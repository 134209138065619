import FormField from './FormField'
import * as yup from 'yup'
import { FieldInputType, FieldType } from '@dnvgl-onefoundation/onedesign-react'

export const assetFormSchema = yup.object().shape({
  imo: yup
    .string()
    .typeError('This field is required')
    .required('This field is required'),
  positionLatitude: yup
    .number()
    .typeError('This field is required')
    .required('This field is required')
    .min(-90, 'It must be between -90 and 90')
    .max(90, 'It must be between -90 and 90'),
  positionLongitude: yup
    .number()
    .typeError('This field is required')
    .required('This field is required')
    .min(-180, 'It must be between -180 and 180')
    .max(180, 'It must be between -180 and 180'),
  name: yup.string().required('This field is required'),
  isUsingSolution: yup.boolean().default(false),
  classSociety: yup.string().required('This field is required'),
  firstOil: yup.date().nullable(),
  location: yup.string().nullable(),
  flag: yup.string().nullable(),
  ownerName: yup.string().nullable(),
  customerName: yup.string().nullable(),
  photoUri: yup.string().url().nullable(),
  vapReportUri: yup.string().url().nullable()
})

export type AssetFormInputs = yup.InferType<typeof assetFormSchema>

export type AssetFormInputKey = keyof AssetFormInputs

interface Props {
  readOnlyFields?: AssetFormInputKey[]
}

const AssetForm = (props: Props) => {
  const { readOnlyFields = [] } = props

  return (
    <>
      <FormField
        readOnly={readOnlyFields.includes('imo')}
        fieldName="imo"
        label="IMO"
        type={FieldType.Input}
        inputType={FieldInputType.Number}
      />
      <FormField
        readOnly={readOnlyFields.includes('name')}
        fieldName="name"
        label="FPSO Name"
        type={FieldType.Input}
      />
      <FormField
        readOnly={readOnlyFields.includes('classSociety')}
        fieldName="classSociety"
        label="Class Society"
        type={FieldType.Input}
      />
      <FormField
        readOnly={readOnlyFields.includes('firstOIL')}
        fieldName="firstOIL"
        label="First Oil"
        type={FieldType.Date}
      />
      <FormField
        readOnly={readOnlyFields.includes('ownerName')}
        fieldName="ownerName"
        label="Owner"
        type={FieldType.Input}
      />
     <FormField
        readOnly={readOnlyFields.includes('customerName')}
        fieldName="customerName"
        label="Customer"
        type={FieldType.Input}
      />
      <FormField
        readOnly={readOnlyFields.includes('location')}
        fieldName="location"
        label="Location"
        type={FieldType.Input}
      />
      <FormField
        readOnly={readOnlyFields.includes('flag')}
        fieldName="flag"
        label="Flag"
        type={FieldType.Input}
      />
      <FormField
        readOnly={readOnlyFields.includes('positionLatitude')}
        fieldName="positionLatitude"
        label="Latitude"
        type={FieldType.Input}
        inputType={FieldInputType.Number}
      />
      <FormField
        readOnly={readOnlyFields.includes('positionLongitude')}
        fieldName="positionLongitude"
        label="Longitude"
        type={FieldType.Input}
        inputType={FieldInputType.Number}
      />
      <FormField
        readOnly={readOnlyFields.includes('photoUri')}
        fieldName="photoUri"
        label="Photo URI"
        type={FieldType.Input}
        inputType={FieldInputType.Url}
      />
      <FormField
        readOnly={readOnlyFields.includes('vapReportUri')}
        fieldName="vapReportUri"
        label="VAP Report URI"
        type={FieldType.Input}
        inputType={FieldInputType.Url}
      />
      <FormField
        readOnly={readOnlyFields.includes('isUsingSolution')}
        fieldName="isUsingSolution"
        label="Is Using Solution"
        type={FieldType.Checkbox}
      />
    </>
  )
}

export default AssetForm
