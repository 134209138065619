const config = {
  version: '1.0.0',
  appName: 'HIS',
  appFullName: 'Hull Integrity Strategy',
  endpoints: {
    assetModels: '/api/AssetModels',
    assets: '/api/Assets',
    companies: '/api/Companies',
    currentUser: '/api/Account/CurrentUser',
    groups: '/api/Groups',
    groupsAdmin: '/api/GroupAdministration/Groups',
    my: '/my',
    services: '/api/Services',
    teams: '/api/Teams',
    toggles: '/api/FeatureToggles',
    userAdministration: '/api/UserAdministration'
  },
  dateTimeFormat: 'DD MMM YYYY, HH:mm',
  authCheckInterval: 1860_000,
  paths: {
    assets: '/assets'
  },
  links: {
    header: {
      logIn: '/api/Account/SignIn',
      logOut: '/api/Account/SignOut',
      signUp: `https://id.veracity.com/sign-up?return-url=${window.location.origin}/api/Account/SignIn`,
      notifications: 'https://services.veracity.com/Notifications',
      support: `mailto:norbert.matynka@dnv.com?subject=Hull Integrity Solution&cc=norbert.matynka@dnv.com&body=Link: ${encodeURIComponent(window.location.href)}`
    },
    footer: {
      big: [
        {
          title: 'Product',
          links: [
            {
              name: 'About',
              path: '/about',
              guestUsers: true
            },
            {
              name: 'How it works',
              path: '/how-it-works',
              guestUsers: true
            }
          ]
        },
        {
          title: 'Legal',
          links: [
            {
              name: 'Privacy statement',
              path: '/privacy',
              guestUsers: true
            },
            {
              name: 'Terms of use',
              path: '/tou',
              guestUsers: true
            },
            {
              name: 'Cookies policy',
              path: '/cookies',
              guestUsers: true
            }
          ]
        }
      ]
    }
  },
  routes: {
    guest: [],
    authenticated: [
      {
        name: 'Assets',
        path: '/assets',
        isCompanySpecific: false
      },
      {
        name: 'Users',
        path: '/admin/UserGroups',
        isCompanySpecific: true
      }
    ],
    admin: []
  },
  assetsList: {
    rowsPerPage: 10,
    fields: [
      // TODO: Check when sync with api
      {
        name: 'imo',
        heading: 'IMO'
      },
      {
        name: 'name',
        heading: 'FPSO Name'
      },
      {
        name: 'classSociety',
        heading: 'Class Society'
      },
      {
        name: 'firstOIL',
        heading: 'First Oil'
      },
      {
        name: 'ownerName',
        heading: 'Owner'
      },
      {
        name: 'customerName',
        heading: 'Customer'
      },
      {
        name: 'location',
        heading: 'Location'
      },
      {
        name: 'flag',
        heading: 'Flag'
      },
      {
        name: 'positionLatitude',
        heading: 'Latitude'
      },
      {
        name: 'positionLongitude',
        heading: 'Longitude'
      }
    ]
  },
  cdnResources: {
    baseUrl: 'https://dnvdirect4test.blob.core.windows.net/assets',
    backgroundVideo: 'background.mp4',
    sentMessageSound: 'sent.mp3'
  }
}

export default config
