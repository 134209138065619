interface Props {
  onClose: () => void
}

const AssetDetailsModalHeader = (props: Props) => {
  const { onClose } = props

  return <i className="fal fa-times modal-close" onClick={onClose} />
}

export default AssetDetailsModalHeader
