import { TeamRightFlag, UserGroupItem } from '../../interfaces'
import config from '../config'
import { get, patch } from './utils'

const { endpoints } = config

const teamsClient = {
  readAll: () => get<UserGroupItem>(endpoints.teams),
  setRight: (teamId: string, right: TeamRightFlag, isSet: boolean) =>
    patch<boolean, UserGroupItem>(
      `${endpoints.teams}/${teamId}/Rights/${right}`,
      isSet
    )
}

export default teamsClient
