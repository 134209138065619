import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useRef } from 'react'
import './MapboxMap.css'
import MapboxAdapter from '../../utils/mapbox'

interface Props {
  markersData: GeoJSON.FeatureCollection
  onMarkerClick: (data: any) => void
}

const MapboxMap = (props: Props) => {
  const { markersData, onMarkerClick } = props
  const mapContainer = useRef<HTMLDivElement>(null)
  const mapControl = useRef<MapboxAdapter>()

  useEffect(() => {
    if (mapContainer.current) {
      mapControl.current = new MapboxAdapter({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        zoom: 1
      })
    }
  }, [])

  useEffect(() => {
    if (mapControl.current) {
      mapControl.current.setMarkersData(markersData)
      mapControl.current.setOnMarkerClick(onMarkerClick)
    }
  }, [markersData, onMarkerClick])

  return <div style={{ height: 'calc(100vh - 64px)' }} ref={mapContainer} />
}

export default MapboxMap
