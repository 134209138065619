import React, { useCallback, useEffect, useState } from 'react'
import {
  OButton,
  OModal,
  OAlert,
  OField,
  FieldType,
  ORow,
  OCol,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../../layout'
import { helper, api } from '../../../utils'
import { VeracityLogo } from '../../helpers'
import { GroupTypeEnum, User, UserIdentity } from '../../../interfaces'
import { useAppSelector } from '../../../store/hooks'
import { useDispatch } from 'react-redux'
import { getUsers } from '../../../store/slices/userGroups'
import { useRouteGroupId } from '../../../hooks'

interface Props {
  currentUser: User
}

const AddUser = (props: Props) => {
  const { currentUser } = props
  const isCustomer = helper.isCustomer(currentUser)
  const [email, setEmail] = useState('')
  const routeGroupId = useRouteGroupId()
  const userGroups = useAppSelector(state => state.userGroups.userGroups)
  const [groupId, setGroupId] = useState<string | undefined>()
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchCompleted, setIsSearchCompleted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [veracityUser, setVeracityUser] = useState<UserIdentity | null>(null)

  const { isValidEmail } = helper

  useEffect(() => {
    setGroupId(routeGroupId ?? userGroups[0]?.id)
  }, [routeGroupId, userGroups])

  const dispatch = useDispatch()
  const reloadUsers = useCallback(() => {
    if (routeGroupId === groupId) dispatch(getUsers(groupId, true))
  }, [dispatch, groupId, routeGroupId])

  const showModal = () => {
    reset()
    setEmail('')
    setIsVisible(true)
  }

  const reset = () => {
    setIsSubmitting(false)
    setIsSearchCompleted(false)
    setIsLoading(false)
    setVeracityUser(null)
  }
  const hideModal = () => setIsVisible(false)
  const handleOK = () => {
    setIsLoading(true)
    return veracityUser?.id ? addUser() : inviteUser()
  }

  const addUser = () =>
    api.userAdministration
      .addVeracityUser(`${veracityUser?.id}`)
      .then(() => {
        reloadUsers()
        hideModal()
        showSuccess(
          'User added',
          `${veracityUser?.firstName} ${veracityUser?.lastName}`
        )
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsLoading(false))

  const inviteUser = () =>
    (isCustomer
      ? api.groups.inviteUser(groupId!, email)
      : api.userAdministration.inviteUser(email)
    )
      .then(() => {
        reloadUsers()
        hideModal()
        showSuccess('User invited', email)
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsLoading(false))

  const getVeracityUser = () => {
    reset()
    setIsLoading(true)
    setIsSearchCompleted(false)
    api.userAdministration
      .findVeracityUser(email)
      .then(data => {
        if (data?.length) setVeracityUser(data[0])
      })
      .finally(() => {
        setIsLoading(false)
        setIsSearchCompleted(true)
      })
  }

  const bodySlot = (
    <>
      <ORow>
        <OCol>
          <div style={{ minHeight: '1rem' }}>
            {isLoading && <SpinIndicator />}
          </div>
        </OCol>
      </ORow>
      <ORow className={`row text-left ${isLoading && 'is-disabled'}`}>
        <OCol col={isCustomer ? '12' : '8'} align="center">
          <OField
            value={{ email }}
            onChange={e => {
              setEmail(e.value as string)
              setIsSearchCompleted(false)
            }}
            field={{
              name: 'email',
              heading: 'User E-Mail',
              type: FieldType.Input
            }}
          />
        </OCol>
        {!isCustomer && (
          <OCol col="4" align="center">
            <OButton
              className="mt-2 w-100"
              iconClass="fal fa-search"
              disabled={!isValidEmail(email)}
              onClick={getVeracityUser}
              variant={isValidEmail(email) ? 'primary' : 'subtle'}>
              Search
            </OButton>
          </OCol>
        )}
        <OCol col="12">
          {!isValidEmail(email) && (
            <OAlert
              variant="danger"
              description="Please provide a valid E-mail address."
              dismissable={false}
            />
          )}
        </OCol>
        {isCustomer && (
          <>
            <OCol col="12">
              <OField
                value={{ groupId }}
                onChange={e => setGroupId(e.value as string)}
                field={{
                  name: 'groupId',
                  heading: 'Team',
                  type: FieldType.Select,
                  options: userGroups.map(x => ({
                    value: x.id,
                    text: `${x.type === GroupTypeEnum.Company ? '' : '● '}${
                      x.name
                    }`
                  }))
                }}
              />
              {!groupId && (
                <OAlert
                  variant="danger"
                  description="Please provide group to add to."
                  dismissable={false}
                />
              )}
            </OCol>
          </>
        )}
        {isSearchCompleted && (
          <OCol>
            <p className="mt-4">
              {veracityUser ? (
                <span>
                  Found user on Veracity:&nbsp;
                  <strong>
                    {veracityUser?.firstName}&nbsp;
                    {veracityUser?.lastName}
                  </strong>
                </span>
              ) : (
                <span>
                  User with the {email} address&nbsp;
                  <strong>has not been found on Veracity</strong>. You can
                  invite the user to join, or provide another E-Mail address.
                </span>
              )}
            </p>
          </OCol>
        )}
      </ORow>
    </>
  )

  const okText = () => {
    if (!isSearchCompleted) return 'Add'
    return veracityUser
      ? `Invite ${veracityUser.firstName} ${veracityUser.lastName}`
      : `Invite ${email}`
  }

  return (
    <>
      <OButton iconClass="fal fa-user" onClick={showModal}>
        Invite User
      </OButton>
      <OModal
        visible={isVisible}
        hideCloseButton
        okText={okText()}
        clickOutside={false}
        titleText={
          <div style={{ minWidth: '560px' }}>
            <span>Invite User</span>
            <div className="float-right">
              <VeracityLogo />
            </div>
          </div>
        }
        bodySlot={
          isSubmitting ? (
            <div className="text-center mt-5">
              <SpinIndicator />
            </div>
          ) : (
            bodySlot
          )
        }
        onOk={handleOK}
        okDisabled={
          (isCustomer && !groupId) ||
          (!isCustomer && !isSearchCompleted) ||
          !email ||
          !isValidEmail ||
          isLoading
        }
        onCancel={hideModal}
      />
    </>
  )
}

export default AddUser
