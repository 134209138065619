import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionCreator, AsyncAction, AsyncActionCreator } from '..'
import { AssetData } from '../../interfaces'
import { api } from '../../utils'
import { getErrorHandler } from './users'

export interface AssetsState {
  list: AssetData[]
  isLoadingList: boolean
  detailsModalData: null | AssetData
  isDetailsModalOpen: boolean
  editModalData: null | AssetData
  isEditModalOpen: boolean
}

const initialState: AssetsState = {
  list: [],
  isLoadingList: false,
  detailsModalData: null,
  isDetailsModalOpen: false,
  editModalData: null,
  isEditModalOpen: false
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<AssetData[]>) => {
      state.list = action.payload
    },
    setIsLoadingList: (state, action: PayloadAction<boolean>) => {
      state.isLoadingList = action.payload
    },
    setDetailsModalData: (state, action: PayloadAction<AssetData | null>) => {
      state.detailsModalData = action.payload
    },
    setIsDetailsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDetailsModalOpen = action.payload
    },
    setEditModalData: (state, action: PayloadAction<AssetData | null>) => {
      state.editModalData = action.payload
    },
    setIsEditModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditModalOpen = action.payload
    }
  }
})

export const { actions } = assetsSlice

export const fetchAssetsList: AsyncActionCreator = () => dispatch => {
  dispatch(actions.setIsLoadingList(true))
  return api.assets
    .readAll()
    .then((assets: AssetData[]) => {
      dispatch(actions.setList(assets))
      dispatch(actions.setIsLoadingList(false))
    })
    .catch(getErrorHandler(dispatch))
}

export const openAssetDetailsModal: (imo: string) => AsyncAction =
  imo => dispatch =>
    api.assets
      .read(imo)
      .then((asset: AssetData) => {
        dispatch(actions.setDetailsModalData(asset))
        dispatch(actions.setIsDetailsModalOpen(true))
      })
      .catch(getErrorHandler(dispatch))

export const closeAssetDetailsModal: ActionCreator = () => dispatch => {
  dispatch(actions.setIsDetailsModalOpen(false))
}

export const openAssetEditModal: (imo: string) => AsyncAction =
  imo => dispatch =>
    api.assets
      .read(imo)
      .then((asset: AssetData) => {
        dispatch(actions.setEditModalData(asset))
        dispatch(actions.setIsEditModalOpen(true))
      })
      .catch(getErrorHandler(dispatch))

export const closeAssetEditModal: ActionCreator = () => dispatch => {
  dispatch(actions.setIsEditModalOpen(false))
}
