import {
  OTable,
  TableAction,
  TableItem
} from '@dnvgl-onefoundation/onedesign-react'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AssetData } from '../../interfaces'
import { useAppSelector } from '../../store/hooks'
import { fetchAssetsList, openAssetEditModal } from '../../store/slices/assets'
import { getErrorHandler } from '../../store/slices/users'
import { api, config } from '../../utils'
import { deleteConfirm } from '../modals'
import AssetEdit from './AssetEdit'

const AssetsList = () => {
  const dispatch = useDispatch()
  const { currentUser } = useAppSelector(state => state.users)
  const { list: assets } = useAppSelector(state => state.assets)
  const {
    assetsList: { rowsPerPage, fields }
  } = config

  const handleEdit = ({ imo }: AssetData) => dispatch(openAssetEditModal(imo))

  const handleDelete = ({ imo, name }: AssetData) =>
    deleteConfirm(name || imo.toString()).then(confirm => {
      if (confirm) {
        api.assets
          .delete(imo)
          .then(() => dispatch(fetchAssetsList()))
          .catch(getErrorHandler(dispatch))
      }
    })

  const actions: TableAction[] = [
    { name: 'Edit', onClick: handleEdit },
    { name: 'Delete', onClick: handleDelete }
  ]
  const openAssetDetails = useCallback((item?: any) => {
    const { vapReportUri } = (item as AssetData) ?? {}
    if (vapReportUri) {
      window.open(vapReportUri, '_blank')
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAssetsList())
    }
  }, [currentUser, dispatch])

  return (
    <>
      <OTable
        className="selectable-table"
        allItems={assets as TableItem[]}
        rowsPerPage={rowsPerPage}
        fields={fields}
        actions={actions}
        small
        showActionIcons
        showColumnsButton={false}
        showOptionsButton={false}
        onFocusRowChange={openAssetDetails}
      />
      <AssetEdit />
    </>
  )
}

export default AssetsList
