import React from 'react'
import { useAppSelector } from '../../store/hooks'
import { OButton, colors } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '../../utils'
import AssetsMap from '../helpers/AssetsMap'

const HomePage = () => {
  const users = useAppSelector(state => state.users)
  const { appFullName, links, cdnResources } = config

  return (
    <main
      className="container-fluid p-0 position-relative"
      style={{ flex: '1' }}>
      {users.currentUser?.id ? (
        <div style={{ height: 'calc(100vh - 64px)' }}>
          <AssetsMap />
        </div>
      ) : (
        <div style={{ backgroundColor: colors.darkBlue, minHeight: '200px' }}>
          <div className="position-absolute w-100">
            <video
              className="w-100"
              autoPlay
              loop
              muted
              src={`${cdnResources.baseUrl}/${cdnResources.backgroundVideo}`}
            />
          </div>
          <div className="position-absolute w-100 text-center">
            <h1 className="dnv-display-regular fg-primary-white d-inline-block mt-5">
              Welcome to {appFullName}!
            </h1>
            {!users.isLoadingCurrentUser && (
              <div className="mt-5">
                <a href={links.header.signUp}>
                  <OButton iconClass="fal fa-user-plus" variant="primary">
                    Sign up
                  </OButton>
                </a>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <a
                  href={`${links.header.logIn}?returnUrl=${encodeURIComponent(
                    window.location.pathname
                  )}`}>
                  <OButton iconClass="fal fa-sign-in-alt" variant="primary">
                    Log in
                  </OButton>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </main>
  )
}

export default React.memo(HomePage)
