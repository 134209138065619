import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../store/hooks'
import { fetchAssetsList } from '../../store/slices/assets'
import AssetCreate from '../helpers/AssetCreate'
import AssetsList from '../helpers/AssetsList'
import { ContentSection, Page } from '../layout'

interface Props {
  title: string
}

const AssetsPage = (props: Props) => {
  const { title } = props
  const dispatch = useDispatch()
  const { currentUser } = useAppSelector(state => state.users)
  const { isLoadingList: isLoading } = useAppSelector(state => state.assets)

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchAssetsList())
    }
  }, [currentUser, dispatch])

  return (
    <Page
      title={title}
      isLoading={isLoading}
      disabled={isLoading}
      actions={<AssetCreate />}>
      <ContentSection>
        <AssetsList />
      </ContentSection>
    </Page>
  )
}

export default AssetsPage
