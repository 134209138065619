import { OModal } from '@dnvgl-onefoundation/onedesign-react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import { closeAssetDetailsModal } from '../../../store/slices/assets'
import AssetDetailsModalBody from './AssetDetailsModalBody'
import AssetDetailsModalHeader from './AssetDetailsModalHeader'
import './style.css'

const AssetDetailsModal = () => {
  const { isDetailsModalOpen, detailsModalData } = useAppSelector(
    state => state.assets
  )
  const { vapReportUri } = detailsModalData ?? {}
  const dispatch = useDispatch()
  const closeModal = useCallback(() => {
    dispatch(closeAssetDetailsModal())
  }, [dispatch])
  const openReport = useCallback(() => {
    if (vapReportUri) {
      window.open(vapReportUri, '_blank')
    }
  }, [vapReportUri])

  return (
    <OModal
      className="asset-details-modal"
      visible={isDetailsModalOpen}
      hideFooter
      onClose={closeModal}
      headerSlot={<AssetDetailsModalHeader onClose={closeModal} />}
      bodySlot={
        <AssetDetailsModalBody
          isButtonDisabled={!vapReportUri}
          data={detailsModalData}
          onDashboardClick={openReport}
        />
      }
    />
  )
}

export default AssetDetailsModal
