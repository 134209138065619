import React, { CSSProperties } from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '../../utils'

const Feedback = () => {
  const fixedContainerStyles = {
    position: 'fixed',
    top: '40%',
    right: '-28px',
    transform: 'rotate(-90deg)'
  }

  return (
    <div style={fixedContainerStyles as CSSProperties}>
      <a href={config.links.header.support}>
        <OButton className="no-print" variant="primary" size="small">
          Feedback
        </OButton>
      </a>
    </div>
  )
}

export default React.memo(Feedback)
