import { CompanyInfo, UserGroupItem } from '../../interfaces'
import config from '../config'
import { get } from './utils'

const { endpoints } = config

const companiesClient = {
  readAll: () => get<UserGroupItem>(endpoints.companies),
  read: (companyId: string) =>
    get<CompanyInfo>(`${endpoints.companies}/${companyId}`)
}

export default companiesClient
