import React, { useCallback, useState } from 'react'
import {
  OButton,
  OModal,
  OAlert,
  OField,
  FieldType,
  showSuccess,
  ORow,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../../layout'
import { CreateUserGroup, GroupTypeEnum, User } from '../../../interfaces'
import { helper, api, groups } from '../../../utils'
import { addGroupConfirm } from '../../modals'
import { useUserGroups } from '../../helpers/hooks'
import { useDispatch } from 'react-redux'
import { getGroups } from '../../../store/slices/userGroups'
import { useNavigate } from 'react-router-dom'
import { useRouteGroupId } from '../../../hooks'

interface Props {
  currentUser: User
}

const AddUserGroup = (props: Props) => {
  const { currentUser } = props
  const navigate = useNavigate()
  const type = helper.isCustomer(currentUser)
    ? GroupTypeEnum.Team
    : GroupTypeEnum.Company
  const displayType = groups.getDisplayType(type)
  const routeGroupId = useRouteGroupId()
  const { companies, getCompany } = useUserGroups()
  const initialValues: CreateUserGroup = {
    name: '',
    type,
    companyId:
      type === GroupTypeEnum.Team ? getCompany(routeGroupId)?.id : undefined
  }
  const [isVisible, setIsVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [payload, setPayload] = useState<CreateUserGroup>(initialValues)
  const { deepClone, handleErrorMessage } = helper
  const dispatch = useDispatch()

  const reloadGroups = useCallback(() => {
    dispatch(getGroups())
  }, [dispatch])

  const showModal = () => {
    setPayload(deepClone(initialValues))
    setIsSubmitting(false)
    setIsVisible(true)
  }
  const hideModal = () => setIsVisible(false)
  const handleOK = () => {
    if (isSubmitting) return undefined
    hideModal()
    addGroupConfirm(payload.name, displayType).then(confirmed => {
      if (confirmed) {
        setIsSubmitting(true)
        api.groups
          .post(payload)
          .then(newCompanyId => {
            showSuccess(`${displayType} created`, payload.name)
            if (!!newCompanyId) {
              navigate(`admin/UserGroups/${newCompanyId}`)
              window.location.reload()
            } else reloadGroups()
          })
          .catch(handleErrorMessage)
          .finally(() => {
            setIsSubmitting(false)
            hideModal()
          })
      }
    })
  }

  const setPayloadProperty = (propertyName: string, value: string) => {
    const clonePayload = deepClone(payload)
    clonePayload[propertyName] = value
    setPayload(clonePayload)
  }

  const bodySlot = (
    <ORow className="text-left">
      <OCol col="12">
        <OField
          value={{ name: payload.name }}
          onChange={e => setPayloadProperty('name', e.value as string)}
          field={{
            name: 'name',
            heading: 'Name',
            type: FieldType.Input
          }}
        />
        {!payload?.name?.length && (
          <OAlert
            variant="danger"
            description="Name is required"
            dismissable={false}
          />
        )}
        {type === GroupTypeEnum.Team && companies?.length > 1 && (
          <>
            <OField
              value={{ companyId: payload.companyId }}
              onChange={e => setPayloadProperty('companyId', e.value as string)}
              field={{
                name: 'companyId',
                heading: 'Company',
                type: FieldType.Select,
                options: companies?.map(x => ({ value: x.id, text: x.name }))
              }}
            />
            {!payload?.companyId && (
              <OAlert
                variant="danger"
                description="Company is required"
                dismissable={false}
              />
            )}
          </>
        )}
      </OCol>
    </ORow>
  )

  return (
    <>
      <OButton iconClass={groups.getIconClass(type, 'fal')} onClick={showModal}>
        {`Create ${displayType}`}
      </OButton>
      <OModal
        visible={isVisible}
        size="sm"
        hideCloseButton
        okText="Create"
        okDisabled={!payload.name?.length}
        clickOutside={false}
        titleText={`Create ${displayType}`}
        bodySlot={
          isSubmitting ? (
            <div className="text-center mt-5">
              <SpinIndicator />
            </div>
          ) : (
            bodySlot
          )
        }
        onOk={handleOK}
        onCancel={hideModal}
      />
    </>
  )
}

export default AddUserGroup
