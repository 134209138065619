import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { AssetData } from '../../../interfaces'
import AssetAttributes from './AssetAttributes'
import AssetPhoto from './AssetPhoto'
import helper from '../../../utils/helper'

interface Props {
  data: AssetData | null
  isButtonDisabled?: boolean
  onDashboardClick: () => void
}

const AssetDetailsModalBody = (props: Props) => {
  const { generateMapItemImageUrl } = helper
  const { data, onDashboardClick, isButtonDisabled } = props
  const { imo, flag, photoUri } = data ?? {}

  return !!data ? (
    <>
      <AssetPhoto url={photoUri || generateMapItemImageUrl(imo, flag)} />
      <AssetAttributes attributes={data} />
      <OButton disabled={isButtonDisabled} onClick={onDashboardClick}>
        Dashboard
      </OButton>
    </>
  ) : null
}

export default AssetDetailsModalBody
