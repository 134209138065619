import React, { useState, ReactNode, useEffect } from 'react'
import {
  OButton,
  OList,
  OModal,
  OTooltip,
  ButtonVariant
} from '@dnvgl-onefoundation/onedesign-react'
import { FilterInput } from '.'
import {
  UserIdentity,
  User,
  UserGroupItem,
  GroupTypeEnum
} from '../../interfaces'

type Item = UserIdentity | User | UserGroupItem

interface Props {
  titleText: ReactNode
  items?: Item[]
  selectedItems?: Item[]
  multiselect?: boolean
  selectionRequired?: boolean
  buttonText?: ReactNode
  iconClass: string
  tooltipContent?: string
  variant?: ButtonVariant
  okText: string
  onSelected?: (ids: string[]) => void
}

const SelectItem = (props: Props) => {
  const {
    titleText,
    items,
    multiselect = false,
    selectionRequired = true,
    buttonText,
    iconClass,
    tooltipContent = 'Update',
    variant = 'flat',
    okText,
    onSelected
  } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>(
    props.selectedItems?.map(x => x.id) ?? []
  )
  const [filteredItems, setFilteredItems] = useState<UserIdentity[]>([])

  useEffect(() => {
    if (props.selectedItems)
      setSelectedItems(props.selectedItems.map(x => x.id))
  }, [props.selectedItems])

  const onOK = () => {
    onSelected?.(selectedItems)
    hideModal()
  }

  const hideModal = () => {
    setIsModalVisible(false)
    setSelectedItems([])
  }

  const filterItems = (value?: string) => {
    if (!multiselect) setSelectedItems([])
    if (!value || !items) return setFilteredItems(items ?? [])
    const query = value.toLocaleLowerCase('en')
    setFilteredItems(
      items.filter(
        (i: UserGroupItem | User) =>
          (i as User)?.firstName?.toLocaleLowerCase('en')?.includes(query) ||
          (i as User)?.lastName?.toLocaleLowerCase('en')?.includes(query) ||
          (i as User)?.email?.toLocaleLowerCase('en')?.includes(query) ||
          (i as UserGroupItem)?.name?.toLocaleLowerCase('en')?.includes(query)
      )
    )
  }

  const onItemSelected = (u: any) => {
    const selected = items?.find(i => i?.id === u?.id)
    if (selected) {
      if (multiselect) {
        setSelectedItems(
          selectedItems?.includes(selected.id)
            ? [...selectedItems?.filter((i: string) => i !== selected.id)]
            : [...selectedItems, selected?.id]
        )
      } else setSelectedItems([selected.id])
    }
  }

  const buildUserRecord = (item: UserIdentity) => (
    <span className="fa-1x">
      <i className="fal fa-user fg-cyan mr-2"></i>
      {`${item?.firstName} ${item?.lastName} (${item?.email})`}
    </span>
  )

  const buildGroupRecord = (item: UserGroupItem) => (
    <span className="fa-1x">
      <i
        className={`${
          item?.type === GroupTypeEnum.Company ? 'fas' : 'fal'
        } fa-users fg-cyan mr-2`}></i>
      {`${item?.type === GroupTypeEnum.Company ? `All in ` : ''}${item?.name}`}
    </span>
  )

  return items?.length ? (
    <>
      <OTooltip content={tooltipContent} placement="bottom">
        <OButton
          iconClass={iconClass}
          variant={variant}
          onClick={() => {
            filterItems()
            setIsModalVisible(true)
          }}>
          {buttonText}
        </OButton>
      </OTooltip>
      <OModal
        visible={isModalVisible}
        titleText={
          <div style={{ minWidth: '28rem' }}>
            <span>{titleText}</span>
            <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
              <FilterInput defaultValue="" onChange={filterItems} />
            </div>
          </div>
        }
        okText={okText}
        hideCloseButton
        onOk={onOK}
        onCancel={hideModal}
        okDisabled={selectionRequired && !selectedItems.length}
        clickOutside={false}
        bodySlot={
          <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
            <OList
              onSelected={onItemSelected}
              selected={selectedItems}
              flush
              small
              items={filteredItems.map((item: any) => {
                return {
                  id: item.id,
                  content: !!item?.fullName
                    ? buildUserRecord(item)
                    : buildGroupRecord(item)
                }
              })}
            />
          </div>
        }
      />
    </>
  ) : null
}

export default React.memo(SelectItem)
