import React from 'react'
import {
  showError,
  showInfo,
  Option
} from '@dnvgl-onefoundation/onedesign-react'
import { getIso3166Countries } from '@dnvgl/i18n'

import {
  Address,
  AgreementFile,
  GroupTypeEnum,
  ServiceDetails,
  User,
  UserRoleEnum
} from '../interfaces'

const countryOptions: Option[] = getIso3166Countries().map(c => {
  return {
    text: c.countryName,
    value: c.alpha2Code
  }
})

const isAuthenticated = (user: User | null) => !!user?.id

const hasRole = (user: User | null, role: UserRoleEnum) =>
  user?.roles?.map(role => role.id).includes(role) ?? false

const isCustomer = (user: User | null) => hasRole(user, UserRoleEnum.Customer)

const isInternal = (user: User | null) => hasRole(user, UserRoleEnum.Internal)

const isSuperAdministrator = (user: User | null) =>
  hasRole(user, UserRoleEnum.SuperAdministrator)

const getUserCompanyIds = (user: User | null) =>
  user?.groups?.filter(g => g.type === GroupTypeEnum.Company)?.map(g => g.id)

const deepClone = (obj: unknown) => JSON.parse(JSON.stringify(obj))
const callAsync = (callback: Function, delay: number = 0) =>
  window.setTimeout(callback, delay)
const addPluralS = (count: number) => (count > 1 ? 's' : '')
const getPercentage = (num: number | undefined) =>
  num ? parseFloat(`${num * 100}`).toPrecision(4) : 0

const truncateString = (
  originalString: string,
  stringLength: number = 10,
  separator: string = '...'
) => {
  if (originalString.length <= stringLength) return originalString

  separator = separator || '...'

  const sepLen = separator.length,
    charsToShow = stringLength - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    originalString.substr(0, frontChars) +
    separator +
    originalString.substr(originalString.length - backChars)
  )
}
const getOptionText = (
  collection: readonly any[],
  value: number | undefined
) => {
  const item = collection.find(
    i => i?.value === value || i?.value === `${value}`
  )
  return item?.text
}

const handleErrorMessage = (error?: any) => {
  console.error('Error >>>')
  console.error(error?.response ? error.response : error)
  const maxMessageLength = 120
  let message = 'An Error Occured!'
  if (error?.response?.status === 401)
    message = 'Session expired. Please refresh and log in.'
  if (error?.response?.status === 403)
    message =
      'Access Denied. You do not have permission to access, or update the data.'
  const data = error?.response?.data
  if (data?.message) message = data.message
  // Handle server errors e.g. request body limit.
  if (data?.errors?.[''][0]) message = data.errors[''][0]

  if (message?.length > maxMessageLength)
    message = `Error: ${truncateString(
      message,
      maxMessageLength
    )}… More details at the developer console.`

  showError('Error', message)
}

const nullHandler = (value: null | string | number | boolean) =>
  value === null ? '–' : value

const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const publishSorter = (s: ServiceDetails) => (s.isPublished ? -1 : 1)

const notifyUnderConstruction = () =>
  showInfo(
    <span>
      <i className="fal fa-space-station-moon-alt"></i>&nbsp;Under construction…
    </span>,
    'The feature is under construction.'
  )

const getCountryName = (countryCode: string) =>
  countryOptions?.find(c => c.value === countryCode)?.text

const addressToString = (address?: Address): string => {
  if (!address) return ''
  const items = []
  if (address?.addressLine1) items.push(address.addressLine1)
  if (address?.addressLine2) items.push(address.addressLine2)
  if (address?.city) items.push(address.city)
  if (address?.countryCode && getCountryName(address?.countryCode))
    items.push(getCountryName(address?.countryCode))
  if (address?.postalCode) items.push(address.postalCode)
  return items.join(', ')
}

const generateMapItemImageUrl = (imo?: string, flag?: string | null) => 
  !!imo && !!flag ? `/api/assets/${imo}/${flag}/image` : !!imo ? `/api/assets/${imo}/image` : '';
//const generateMapItemImageUrl = (imo?: number, location?: string | null) => !!imo ? `/api/assets/${imo}/image` : '';

const checkAgreementsAccepted = (agreements: AgreementFile[]): boolean =>
  agreements.some(a => a.acceptance)

const helper = {
  countryOptions,
  isAuthenticated,
  hasRole,
  isCustomer,
  isInternal,
  isSuperAdministrator,
  getUserCompanyIds,
  deepClone,
  callAsync,
  addPluralS,
  getPercentage,
  truncateString,
  getOptionText,
  handleErrorMessage,
  nullHandler,
  isValidEmail,
  publishSorter,
  notifyUnderConstruction,
  getCountryName,
  addressToString,
  checkAgreementsAccepted,
  generateMapItemImageUrl
}

export default helper
