import assetModelsClient from './assetModelsClient'
import assetsClient from './assetsClient'
import companiesClient from './companiesClient'
import groupAdministrationClient from './groupsAdministrationClient'
import groupsClient from './groupsClient'
import servicesClient from './servicesClient'
import teamsClient from './teamsClient'
import userAdministrationClient from './userAdministrationClient'
import usersClient from './usersClient'

// HINT: To differentiate from human requests that redirects
// to login page instead of returning 401.

const api = {
  assetModels: assetModelsClient,
  assets: assetsClient,
  companies: companiesClient,
  groups: groupsClient,
  groupsAdministration: groupAdministrationClient,
  services: servicesClient,
  teams: teamsClient,
  userAdministration: userAdministrationClient,
  users: usersClient
}

export default api
