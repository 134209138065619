import { CreateUserGroup, User, UserGroupItem } from '../../interfaces'
import config from '../config'
import { del, get, post, put } from './utils'

const { endpoints } = config

const groupsClient = {
  fetch: () => get<UserGroupItem[]>(endpoints.groups),
  fetchGroupUsers: (groupId: string) =>
    get<User[]>(`${endpoints.groups}/${groupId}/Users`),
  post: (payload: CreateUserGroup) =>
    post<CreateUserGroup, string>(endpoints.groups, payload),
  addUser: (groupId: string, userId: string) =>
    put<void, void>(`${endpoints.groups}/${groupId}/Users/${userId}`),
  addUsers: (groupId: string, userIds: string[]) =>
    put<string[], void>(`${endpoints.groups}/${groupId}/Users`, userIds),
  inviteUser: (groupId: string, email: string) =>
    put<void, void>(`${endpoints.groups}/${groupId}/Users/${email}`),
  deleteUser: (groupId: string, userId: string) =>
    del(`${endpoints.groups}/${groupId}/Users/${userId}`)
}

export default groupsClient
