import { AssetData } from '../../../interfaces'
import AssetAttribute from './AssetAttribute'

interface Props {
  attributes: AssetData
}

const AssetAttributes = (props: Props) => {
  const { attributes } = props

  return (
    <section className="asset-attributes">
      <AssetAttribute name="Name" value={attributes.name} />
      <AssetAttribute name="IMO" value={attributes.imo} />
      <AssetAttribute name="Class Society" value={attributes.classSociety} />
      <AssetAttribute name="First Oil" value={attributes.firstOIL} />
      <AssetAttribute name="Owner" value={attributes.ownerName} />
      <AssetAttribute name="Customer" value={attributes.customerName} />
      <AssetAttribute name="Location" value={attributes.location} />
      <AssetAttribute name="Flag" value={attributes.flag} />
      <AssetAttribute name="Latitude" value={attributes.positionLatitude} />
      <AssetAttribute name="Longitude" value={attributes.positionLongitude} />
    </section>
  )
}

export default AssetAttributes
