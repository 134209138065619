import { OButton, OModal } from '@dnvgl-onefoundation/onedesign-react'
import { useCallback, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import AssetForm, { AssetFormInputs, assetFormSchema } from './AssetForm'
import { api } from '../../utils'
import { getErrorHandler } from '../../store/slices/users'
import { useDispatch } from 'react-redux'
import { fetchAssetsList } from '../../store/slices/assets'

const AssetCreate = () => {
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false)

  const form = useForm<AssetFormInputs>({
    resolver: yupResolver(assetFormSchema)
  })

  const hasErrors = Object.keys(form.formState.errors).length > 0

  const openModal = useCallback(() => {
    setIsVisible(true)
    form.reset({})
  }, [form])
  const closeModal = useCallback(() => setIsVisible(false), [])

  const handleSubmit = useCallback(() => {
    const handleCreate: SubmitHandler<AssetFormInputs> = values => {
      api.assets
        .create(values as any)
        .then(() => {
          dispatch(fetchAssetsList())
          closeModal()
        })
        .catch(getErrorHandler(dispatch))
    }

    form.handleSubmit(handleCreate)()
  }, [closeModal, form, dispatch])

  return (
    <>
      <OButton iconClass="fal fa-plus" onClick={openModal}>
        Create asset
      </OButton>
      <OModal
        visible={isVisible}
        onClose={closeModal}
        onCancel={closeModal}
        titleText={
          <div style={{ minWidth: '560px' }}>
            <span>Create asset</span>
          </div>
        }
        hideCloseButton
        okText="Create"
        clickOutside={false}
        onOk={handleSubmit}
        okDisabled={hasErrors}
        bodySlot={
          <FormProvider {...form}>
            <AssetForm />
          </FormProvider>
        }
      />
    </>
  )
}

export default AssetCreate
