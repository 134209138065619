import React from 'react'
import {
  OHeader,
  OHeaderDropdownSeparator,
  OHeaderDropdownLink,
  OHeaderUser,
  OHeaderNotifications,
  OButton,
  OLogo
} from '@dnvgl-onefoundation/onedesign-react'
import { User, UserRoleEnum, HeaderRouteExtended } from '../../interfaces'
import { UserRoleIndicator } from '../helpers'
import { helper } from '../../utils'

interface Props {
  routes: HeaderRouteExtended[]
  appName: string
  logInLink: string
  logOutLink: string
  notificationsLink: string
  notificationsCount: number
  signUpLink: string
  isLoadingCurrentUser: boolean
  companyId?: string
  isHomePage: boolean
  user: User | null
}

const SiteHeader = (props: Props) => {
  const {
    routes,
    appName,
    logInLink,
    logOutLink,
    signUpLink,
    notificationsLink,
    notificationsCount,
    isLoadingCurrentUser,
    companyId,
    isHomePage,
    user
  } = props

  const { hasRole, deepClone } = helper

  const patchRoutes = (routes: HeaderRouteExtended[]) => {
    return routes.map(r => {
      const route = deepClone(r)
      route.path = route.isCompanySpecific
        ? `${route.path}/${companyId}`
        : route.path
      return route
    })
  }

  return (
    <OHeader
      logoSrc={process.env.REACT_APP_LOGO_SRC}
      compact
      container={false}
      routes={companyId ? patchRoutes(routes) : routes}>
      {isHomePage && !user ? null : (
        <div className="o-header-right">
          <span className="o-header-product">{appName}</span>
          <ul className="o-header-actions">
            {!!user ? (
              <>
                <OHeaderNotifications
                  path={notificationsLink}
                  count={notificationsCount}
                  target="_blank"
                />
                <UserRoleIndicator user={user} className="p-2" />
                <OHeaderUser
                  userFirstName={user?.firstName}
                  userLastName={user?.lastName}>
                  <div className="o-header-dropdown-menu-item">
                    <small>{user?.email}</small>
                  </div>
                  {hasRole(user as User, UserRoleEnum.Internal) ? (
                    <OLogo className="p-3" />
                  ) : (
                    <div className="o-header-dropdown-menu-item">
                      <span>
                        {user?.groups?.map(group => group.name).join(', ')}
                      </span>
                    </div>
                  )}
                  <OHeaderDropdownSeparator />
                  <OHeaderDropdownLink
                    text={
                      <>
                        <i className="fal fa-sign-out-alt mr-2"></i>
                        <span>Log out</span>
                      </>
                    }
                    href={logOutLink}
                  />
                </OHeaderUser>
              </>
            ) : isLoadingCurrentUser ? (
              <section className="o-header-user">
                <p>&nbsp;</p>
              </section>
            ) : (
              <>
                <a href={signUpLink}>
                  <OButton iconClass="fal fa-user-plus" variant="flat">
                    Sign up
                  </OButton>
                </a>
                <a
                  href={`${logInLink}?returnUrl=${encodeURIComponent(
                    window.location.pathname
                  )}`}>
                  <OButton iconClass="fal fa-sign-in-alt" variant="flat">
                    Log in
                  </OButton>
                </a>
              </>
            )}
          </ul>
        </div>
      )}
    </OHeader>
  )
}

export default React.memo(SiteHeader)
