import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { AssetData } from '../../interfaces'
import { useAppSelector } from '../../store/hooks'
import {
  fetchAssetsList,
  openAssetDetailsModal
} from '../../store/slices/assets'
import AssetDetailsModal from '../modals/AssetDetailsModal'
import MapboxMap from './MapboxMap'

const AssetsMap = () => {
  const dispatch = useDispatch()
  const { list } = useAppSelector(state => state.assets)

  const markers = useMemo<GeoJSON.FeatureCollection>(() => {
    return {
      type: 'FeatureCollection',
      features:
        list?.map(
          (asset: AssetData): GeoJSON.Feature<GeoJSON.Point> => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [asset.positionLongitude, asset.positionLatitude]
            },
            properties: {
              id: asset.imo,
              label: asset.name ?? asset.imo.toString(),
              variant: asset.isUsingSolution ? 'internal' : 'external'
            }
          })
        ) ?? []
    }
  }, [list])

  useEffect(() => {
    dispatch(fetchAssetsList())
  }, [dispatch])

  const handleMarkerClick = useCallback(
    ({ id }: { id: string }) => {
      dispatch(openAssetDetailsModal(id))
    },
    [dispatch]
  )

  return (
    <>
      <MapboxMap markersData={markers} onMarkerClick={handleMarkerClick} />
      <AssetDetailsModal />
    </>
  )
}

export default AssetsMap
