import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { usersSlice, UsersState } from './slices/users'
import { userGroupsSlice, UserGroupState } from './slices/userGroups'
import { assetsSlice, AssetsState } from './slices/assets'

export interface ApplicationState {
  users: UsersState
  userGroups: UserGroupState
  assets: AssetsState
}

const store = configureStore({
  reducer: {
    users: usersSlice.reducer,
    userGroups: userGroupsSlice.reducer,
    assets: assetsSlice.reducer
  }
})

export type ApplicationDispatch = typeof store.dispatch

export interface Action
  extends ThunkAction<void, ApplicationState, null, AnyAction> {}

export interface ActionCreator {
  (): Action
}

export interface AsyncAction
  extends ThunkAction<void | Promise<any>, ApplicationState, null, AnyAction> {}

export interface AsyncActionCreator {
  (): AsyncAction
}

export default store
