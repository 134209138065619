import { User, UserIdentity } from '../../interfaces'
import config from '../config'
import { get } from './utils'

const { endpoints } = config

const usersClient = {
  fetchCurrentUser: () => get<User | undefined>(endpoints.currentUser),
  fetchByGroup: (groupId: string | undefined) => {
    const path = groupId
      ? `${endpoints.groups}/${groupId}/Users`
      : `${endpoints.userAdministration}/UnassignedUsers`
    return get<UserIdentity[]>(path)
  },
  fetchNotificationsCount: () => get<number>(`${endpoints.my}/messages/count`)
}

export default usersClient
