import {
  FieldInputType,
  FieldType,
  OField,
  Option
} from '@dnvgl-onefoundation/onedesign-react'
import { useCallback, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'

interface Props {
  fieldName: string
  label: string
  type: FieldType
  inputType?: FieldInputType
  options?: Option[]
  readOnly?: boolean
}

const FormField = (props: Props) => {
  const { fieldName, label, type, inputType, options, readOnly } = props
  const { control } = useFormContext()
  const {
    field: { name, onChange, value },
    fieldState: { error }
  } = useController({ name: fieldName, control })

  const handleChange = useCallback(
    (event: { id: string; value: any }) => {
      onChange(event.value)
    },
    [onChange]
  )

  const field = useMemo(
    () => ({
      name,
      heading: label,
      type,
      inputType,
      options,
      invalidFeedback: error?.message,
      validate: () => !Boolean(error)
    }),
    [name, label, type, inputType, options, error]
  )

  return (
    <OField
      readOnly={readOnly}
      validated={Boolean(error)}
      value={value}
      onChange={handleChange}
      field={field as any}
    />
  )
}

export default FormField
