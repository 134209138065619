import { AssetData } from '../../interfaces'
import config from '../config'
import { del, get, patch, post } from './utils'

const { endpoints } = config

const assetsClient = {
  readAll: () => get(endpoints.assets),
  read: (imo: string) => get(`${endpoints.assets}/${imo}`),
  create: (data: AssetData) => post(endpoints.assets, data),
  edit: ({ imo, ...data }: Partial<AssetData>) =>
    patch(`${endpoints.assets}/${imo}`, data),
  delete: (imo: string) => del(`${endpoints.assets}/${imo}`)
}

export default assetsClient
