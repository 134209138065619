import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import {
  OContainer,
  ORow,
  OCol,
  O404,
  OFooter
} from '@dnvgl-onefoundation/onedesign-react'
import {
  SiteHeader,
  Page,
  ContentSection,
  ProtectedContent,
  Feedback
} from './components/layout'
import { AssetsPage, HomePage } from './components/pages'
import {
  AboutPage,
  PrivacyPage,
  TouPage,
  HowItWorksPage,
  CookiesPolicyPage
} from './components/pages/footer'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { getCurrentUser, checkNotifications } from './store/slices/users'
import { config, helper } from './utils'
import { GroupTypeEnum, UserRoleEnum } from './interfaces'
import AdminContainer from './components/admin/users/AdminContainer'

const { routes, appFullName, paths, links, authCheckInterval } = config
const { isAuthenticated, isSuperAdministrator } = helper

const adminRoles = [UserRoleEnum.SuperAdministrator]
const internalRoles = [UserRoleEnum.Internal, ...adminRoles]
const allRoles = [UserRoleEnum.Customer, ...internalRoles]

const guestRoutes = routes.guest
const authenticatedRoutes = [...guestRoutes, ...routes.authenticated]
const adminRoutes = [...authenticatedRoutes, ...routes.admin]

const App = () => {
  const location = useLocation()

  const dispatch = useAppDispatch()
  const {
    currentUser,
    isLoadingCurrentUser,
    isAuthSessionExpired,
    notificationsCount
  } = useAppSelector(state => state.users)

  const [appRoutes, setAppRoutes] = useState<any[]>([])

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  useEffect(() => {
    let intervalId: any = undefined
    if (currentUser?.id) {
      dispatch(checkNotifications())
      if (!isAuthSessionExpired)
        intervalId = setInterval(() => {
          dispatch(checkNotifications())
        }, authCheckInterval)
    }

    setAppRoutes(
      isSuperAdministrator(currentUser)
        ? adminRoutes
        : isAuthenticated(currentUser)
        ? authenticatedRoutes
        : guestRoutes
    )

    return () => intervalId && clearInterval(intervalId)
  }, [isAuthSessionExpired, currentUser, dispatch])

  const isHomePage = location?.pathname === '/'

  return (
    <>
      <SiteHeader
        routes={appRoutes}
        appName={appFullName}
        logInLink={links.header.logIn}
        logOutLink={links.header.logOut}
        signUpLink={links.header.signUp}
        notificationsLink={links.header.notifications}
        notificationsCount={notificationsCount}
        companyId={
          currentUser?.groups?.find(
            group => group.type === GroupTypeEnum.Company
          )?.id
        }
        isLoadingCurrentUser={isLoadingCurrentUser}
        isHomePage={isHomePage}
        user={currentUser}
      />
      {isHomePage ? (
        <HomePage />
      ) : (
        <OContainer main>
          <ORow>
            <OCol col="12" className="mt-2">
              <Routes>
                <Route path="/about" element={<AboutPage />} />
                <Route path="/how-it-works" element={<HowItWorksPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/tou" element={<TouPage />} />
                <Route path="/cookies" element={<CookiesPolicyPage />} />
                <Route
                  path="/Error"
                  element={
                    <Page title="Error">
                      <ContentSection
                        icon="fal fa-exclamation-circle text-danger"
                        title="An error occured. Please try again later."
                      />
                    </Page>
                  }
                />
                <Route
                  path={paths.assets}
                  element={
                    <ProtectedContent>
                      <AssetsPage title="Assets" />
                    </ProtectedContent>
                  }
                />
                <Route
                  path="/admin/:section/*"
                  element={
                    <ProtectedContent roles={allRoles}>
                      <AdminContainer currentUser={currentUser!} />
                    </ProtectedContent>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Page>
                      <div style={{ height: '30rem' }}>
                        <O404 />
                      </div>
                    </Page>
                  }
                />
              </Routes>
            </OCol>
          </ORow>
        </OContainer>
      )}

      <OFooter
        small={!isHomePage}
        links={links.footer.big}
        smallLinks={links.footer.big.flatMap(x => x.links)}
        socialMediaLinks={isHomePage ? undefined : []}
        newTab
        className="mt-4"
      />
      {isAuthenticated(currentUser) && <Feedback />}
    </>
  )
}

export { App }
