import { useMemo, useState } from "react";
import noImageTemplate from '../../../assets/no-image.webp';

interface Props {
    url?: string | null
}

const AssetPhoto = (props: Props) => {
    const [isImgLoadFailed, setIsImgLoadFailed] = useState(false);
    const { url } = props

    const imageUrl = useMemo(() => !!url && !isImgLoadFailed ? url : noImageTemplate, [isImgLoadFailed, url]);

    const handleImgLoadingError = () => {
        setIsImgLoadFailed(true);
    }

    return (
        <figure className="asset-photo-container">
            <img className="asset-photo" src={imageUrl} alt="asset" onError={handleImgLoadingError} />
        </figure>
    )
}

export default AssetPhoto
