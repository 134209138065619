import { ReactNode } from 'react'

interface Props {
  name: string
  value: ReactNode
}

const AssetAttribute = (props: Props) => {
  const { name, value } = props

  return (
    <>
      <span className="attribute-name">{name}</span>
      <span className="attribute-value">{value}</span>
    </>
  )
}

export default AssetAttribute
