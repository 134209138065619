import React, { ChangeEvent } from 'react'

interface Props {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  iconClassName?: string
  className?: string
  onChange?: (
    value: string,
    nativeEvent?: ChangeEvent<HTMLInputElement>
  ) => void
}

const FilterInput = (props: Props) => {
  const {
    defaultValue = '',
    placeholder = 'Filter…',
    disabled = false,
    iconClassName = 'fal fa-filter',
    className,
    onChange
  } = props

  const classes = ['input-group', 'd-flex', 'align-items-center']
  if (className) classes.push(className)

  return (
    <span className={classes.join(' ')}>
      <i
        className={`${iconClassName} fg-digital-blue mr-2`}
        style={{ opacity: disabled ? 0.5 : 'auto' }}></i>
      <input
        className="d-inline-block px-2 border-0"
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={nativeEvent =>
          onChange?.(nativeEvent.target.value, nativeEvent)
        }
      />
    </span>
  )
}

export default React.memo(FilterInput)
