import config from '../config'
import { get } from './utils'

const { endpoints } = config

const assetModelsClient = {
  readAll: () => get(endpoints.assetModels),
  read: (id: number) => get(`${endpoints.assetModels}/${id}`)
}

export default assetModelsClient
