import { UpdateUserRole, UserIdentity, UserRole } from '../../interfaces'
import config from '../config'
import { get, patch, post } from './utils'

const { endpoints } = config

const userAdministrationClient = {
  fetchRoles: () => get<UserRole[]>(`${endpoints.userAdministration}/Roles`),
  patchRole: (payload: UpdateUserRole) =>
    patch<UpdateUserRole, void>(
      `${endpoints.userAdministration}/Role`,
      payload
    ),
  findVeracityUser: (email: string) =>
    post<string, UserIdentity[]>(
      `${endpoints.userAdministration}/FindVeracityUser`,
      email
    ),
  addVeracityUser: (userId: string) =>
    post<void, void>(
      `${endpoints.userAdministration}/AddVeracityUser/${userId}`
    ),
  inviteUser: (email: string) =>
    post<string, string>(`${endpoints.userAdministration}/InviteUser`, email),
  fetchInternalUsers: () =>
    get<UserIdentity[]>(`${endpoints.userAdministration}/InternalUsers`)
}

export default userAdministrationClient
