import React from 'react'
import { ContentSection } from '../../layout'
import { config } from '../../../utils'

const ContactSupportSnippet = () => (
  <ContentSection title="Need help?">
    <p>
      Please <a href={config.links.header.support}> send email</a> to the DNV Direct
      development team.
    </p>
  </ContentSection>
)

export default React.memo(ContactSupportSnippet)
