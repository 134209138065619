import { OModal } from '@dnvgl-onefoundation/onedesign-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../store/hooks'
import { closeAssetEditModal, fetchAssetsList } from '../../store/slices/assets'
import { api } from '../../utils'
import AssetForm, { AssetFormInputs, assetFormSchema } from './AssetForm'

const AssetEdit = () => {
  const dispatch = useDispatch()
  const { editModalData, isEditModalOpen } = useAppSelector(
    state => state.assets
  )

  const form = useForm<AssetFormInputs>({
    resolver: yupResolver(assetFormSchema)
  })

  useEffect(() => {
    if (editModalData) {
      form.reset(editModalData as any)
    }
  }, [form, editModalData])

  const hasErrors = Object.keys(form.formState.errors).length > 0

  const closeModal = useCallback(() => {
    dispatch(closeAssetEditModal())
  }, [dispatch])

  const handleSubmit = useCallback(() => {
    const handleEdit: SubmitHandler<AssetFormInputs> = values => {
      api.assets.edit(values).then(() => {
        dispatch(fetchAssetsList())
        closeModal()
      })
    }

    form.handleSubmit(handleEdit)()
  }, [closeModal, form, dispatch])

  return (
    <OModal
      visible={isEditModalOpen}
      onClose={closeModal}
      onCancel={closeModal}
      titleText={
        <div style={{ minWidth: '560px' }}>
          <span>Edit asset</span>
        </div>
      }
      hideCloseButton
      clickOutside={false}
      onOk={handleSubmit}
      okDisabled={hasErrors}
      bodySlot={
        <FormProvider {...form}>
          <AssetForm readOnlyFields={['imo']} />
        </FormProvider>
      }
    />
  )
}

export default AssetEdit
