import {
  CreateService,
  UpdatePropertyRequest,
  ServiceDetails,
  ServiceItem
} from '../../interfaces'
import config from '../config'
import { post, patch, get, del } from './utils'

const { endpoints } = config

const servicesClient = {
  create: (payload: CreateService) =>
    post<CreateService, string>(endpoints.services, payload),
  patch: (id: string, payload: UpdatePropertyRequest) =>
    patch<UpdatePropertyRequest, ServiceDetails>(
      `${endpoints.services}/${id}`,
      payload
    ),
  readAll: () => get<ServiceDetails[]>(endpoints.services),
  readPublished: () => get<ServiceItem[]>(`${endpoints.services}/Published`),
  read: (id: string) => get<ServiceDetails>(`${endpoints.services}/${id}`),
  delete: (id: string) => del(`${endpoints.services}/${id}`)
}

export default servicesClient
