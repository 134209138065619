import { UpdatePropertyRequest, UserGroupItem } from '../../interfaces'
import config from '../config'
import { patch } from './utils'

const { endpoints } = config

const groupAdministrationClient = {
  patch: (groupId: string, payload: UpdatePropertyRequest) =>
    patch<UpdatePropertyRequest, UserGroupItem>(
      `${endpoints.groupsAdmin}/${groupId}`,
      payload
    )
}

export default groupAdministrationClient
