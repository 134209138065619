import React from 'react'
import { Page, ContentSection } from '../../layout'
import AdminUsers from './AdminUsers'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import AddUser from './AddUser'
import AddUserGroup from './AddUserGroup'
import { User } from '../../../interfaces'

interface Props {
  currentUser: User
}

const AdminContainer = (props: Props) => {
  const { currentUser } = props

  const { isLoadingUserGroups, isLoadingUsers } = useSelector(
    (state: ApplicationState) => state.userGroups
  )

  return (
    <Page
      isLoading={isLoadingUserGroups || isLoadingUsers}
      disabled={isLoadingUserGroups || isLoadingUsers}
      title="Users"
      actions={
        <>
          <AddUser currentUser={currentUser} />
          &nbsp;
          <AddUserGroup currentUser={currentUser} />
        </>
      }
      children={
        <div className="row">
          <div className="col-12">
            <ContentSection className="p-0">
              <AdminUsers currentUser={currentUser} />
            </ContentSection>
          </div>
        </div>
      }
    />
  )
}

export default AdminContainer
